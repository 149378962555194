<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
				<v-card class="shadowCard">
					<v-card-title class="text-subtitle-1">
					  Encuesta de satisfacción
					  <v-spacer></v-spacer>
	      		<v-btn
							color="primary"
							dark
							@click="initialize()"
							small
							tile
						>
							Actualizar
						</v-btn>
					</v-card-title>
					<v-card-text >

		      	<v-row>
		      		<v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo"
                  item-text="ciclo"
                  item-value="id_ciclo"
                  return-object
                >
                </v-autocomplete>
              </v-col>
              <v-col>
              	<v-radio-group
						      v-model="opEscuela"
						      row
						    >
						      <v-radio
						        label="Inbi"
						        :value="1"
						      ></v-radio>
						      <v-radio
						        label="Fast"
						        :value="2"
						      ></v-radio>
						    </v-radio-group>
              </v-col>
		      		<v-spacer></v-spacer>
			      	<v-col cols="12" sm="6" md="6" lg="4" xl="4" class="pb-0">
			          <v-text-field
			            v-model="search"
			            dense
			            filled
			            label="Buscar"
			            append-icon="mdi-magnify"
			          ></v-text-field>  
			        </v-col>
		      	</v-row>

		        <v-tabs
		          v-model="tab"
		          dense
		          align-with-title
		        >
		          <v-tabs-slider></v-tabs-slider>

		          <v-tab v-if="getdatosUsuario.idpuesto != 82">Nivel y Cat.</v-tab>
		          <v-tab v-if="getdatosUsuario.idpuesto != 82">Categoría</v-tab>
		          <v-tab v-if="getdatosUsuario.idpuesto != 82">Suc y Cat.</v-tab>
		          <v-tab>Teacher y Categoría</v-tab>
		          <v-tab>Faltantes</v-tab>
		          <!-- <v-tab>Comentarios</v-tab> -->
		        </v-tabs>

		        <v-tabs-items v-model="tab">

		        	<!-- Nómina -->
				      <v-tab-item v-if="getdatosUsuario.idpuesto != 82">
				        <v-card flat>
				          <v-card-text>
						        <v-data-table
						          :headers="headersNivel"
						          :items="nivel"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Nómina -->
				      <v-tab-item v-if="getdatosUsuario.idpuesto != 82">
				        <v-card flat>
				          <v-card-text>
						        <v-data-table
						          :headers="headersCategoria"
						          :items="categorias"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Nómina -->
				      <v-tab-item v-if="getdatosUsuario.idpuesto != 82">
				        <v-card flat>
				          <v-card-text>
						        <v-data-table
						          :headers="headersDepartamento"
						          :items="departamentos"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Nómina -->
				      <v-tab-item>
				        <v-card flat>
				        	<v-card-title primary-title>
				        	  Competencia de los profesores: ¿Qué tan preparados y capacitados consideras que están los profesores para enseñar el idioma inglés?
				        	</v-card-title>
				          <v-card-text>
						        <v-data-table
						          :headers="headersTeachers"
						          :items="teachers"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Nómina -->
				      <v-tab-item>
				        <v-card flat>
				          <v-card-text class="black--text">
				          	<v-row>
				          		<v-col cols="12">
				          			<div>
				          				<b>Total: {{ alumnos }}</b><br/>
				          				<b>Faltan: {{ faltantes.length }}</b><br/>
				          				<b>Avance: {{ ((( alumnos - faltantes.length ) / faltantes.length ) * 100 ).toFixed(2 ) }} % </b>
				          			</div>
				          		</v-col>
				          	</v-row>
						        <v-data-table
						          :headers="headersFaltantes"
						          :items="faltantes"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item>

				      <!-- Nómina -->
				      <!-- <v-tab-item>
				        <v-card flat>
				          <v-card-text>
						        <v-data-table
						          :headers="headersComentario"
						          :items="comentarios"
						          class="elevation-0"
						          dense
						          :search="search"
						        >
						        </v-data-table>
				          </v-card-text>
				        </v-card>
				      </v-tab-item> -->

				    </v-tabs-items>



		      </v-card-text>
				</v-card> 
			</v-col>
		</v-row>

		<!-- Dialog de carga -->
		<Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
		<carga                v-if="cargar"/>

	</v-container>
</template>

<script>
	import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
	import Alerta                from '@/components/alertas/Alerta.vue'
	import carga                 from '@/components/alertas/carga.vue';

	import validarErrores       from '@/mixins/validarErrores'
	import XLSX                 from 'xlsx'
	import funcionesExcel       from '@/mixins/funcionesExcel'
	import mixinNominaSabatina  from '@/mixins/mixinNominaSabatina'
	import mixinNominaQuincena  from '@/mixins/mixinNominaQuincena'

	export default {
		components:{
			Alerta,
			carga,
		},

		mixins: [ validarErrores, funcionesExcel, mixinNominaSabatina, mixinNominaQuincena ],

		data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      headers1: [
        { text: 'id'                , value: 'id_teacher'           },
        { text: 'Teacher'           , value: 'teacher'              },  
        { text: 'Categoría'         , value: 'aceptado'             },  
        { text: 'HSBC'              , value: 'hsbc'                 },  
        { text: 'Plantel'           , value: 'plantel'              },  
	      { text: 'existeCategoria'   , value: 'existeCategoria'      },  
        { text: 'PAGO_SABADO'       , value: 'PAGO_SABADO', align: 'end'},  
      ],

      headersCategoria: [
        { text: 'Categoría'       , value: 'categoria'           },  
        { text: 'Promedio'        , value: 'promedio'            },  
      ],

      headersComentario:[
      	{ text: 'Categoría'         , value: 'categoria'          },  
        { text: 'Comentario'        , value: 'comentario'         },  
      ],

      headersFaltantes:[
      	{ text: 'Nombre'         , value: 'alumno'                },  
      	{ text: 'Plantel'        , value: 'plantel'               },  
      ],


			headersJefe: [
        { text: 'Jefe'            , value: 'nombre_completo'     },  
        { text: 'Categoría'       , value: 'categoria'           },  
        { text: 'Promedio'        , value: 'promedio'            },  
      ],


      headersEscuela: [
        { text: 'Escuela'         , value: 'unidad_negocio'      },  
        { text: 'Categoría'       , value: 'categoria'           },  
        { text: 'Promedio'        , value: 'promedio'            },  
      ],

      headersDepartamento: [
        { text: 'Plantel'         , value: 'plantel'      },  
        { text: 'Categoría'       , value: 'categoria'           },  
        { text: 'Promedio'        , value: 'promedio'            },  
      ],

      headersNivel: [
        { text: 'Nivel'           , value: 'nivel'      },  
        { text: 'Categoría'       , value: 'categoria'           },  
        { text: 'Promedio'        , value: 'promedio'            },  
      ],

      headersTeachers: [
        { text: 'Teacher'         , value: 'nombre_completo'     },  
        { text: 'Categoría'       , value: 'categoria'           },  
        { text: 'Promedio'        , value: 'promedio'            },  
      ],

			search:'',
      data:[],

      tab: null,
			ciclos:[],
      ciclo:null,
      faltantes:[],
			categorias:[],
			comentarios:[],
			jefe:[],
			escuela:[],
			opEscuela: 1,
			departamentos:[],
			nivel:[],
			teachers:[],
			alumnos:0,
		}),

		computed: {

			...mapGetters( 'login' ,['getdatosUsuario']),

		},

		watch: {
			ciclo( value ){

				if( value && this.opEscuela ){ this.initialize() }

			},


			opEscuela( value ){

				if( value && this.ciclo ){ this.initialize() }

			}
		},

		async created () {
    	await this.getCiclos()
		},

		methods:{

   		getCiclos () {
      	this.cargar = true
        this.ciclos = []
        return this.$http.get('ciclos.activos.erp').then(response=>{
        	this.ciclos = response.data.filter( el => !el.ciclo.match('FE') && !el.ciclo.match('INVER') && !el.ciclo.match('CAMBIOS') && !el.ciclo.match('EXCI') )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

			exportar( ){
        this.exportExcel( this.data  , 'teachers')
			},

			initialize ( ) {
				if( !this.ciclo ){ return false }
				this.cargar = true
				const payload = {
          id_ciclo : this.opEscuela == 1 ? this.ciclo.id_ciclo : this.ciclo.id_ciclo_relacionado,
          iderp    : this.getdatosUsuario.iderp,
          idpuesto : this.getdatosUsuario.idpuesto,
          planteles: this.getdatosUsuario.planteles
        };

				this.$http.post('encuestas.satisfaccion.resultados', payload).then(response=>{
					this.faltantes     = response.data.faltantes
					this.categorias    = response.data.categorias
					this.comentarios   = response.data.comentarios
					this.jefe          = response.data.jefe
					this.escuela       = response.data.escuela
					this.departamentos = response.data.departamentos
					this.nivel         = response.data.nivel
					this.teachers      = response.data.teachers
					this.alumnos       = response.data.alumnos.length
					this.cargar       = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })	
			},
    }
	}
</script>



